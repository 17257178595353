import { Drawer, Button, Card, Steps, Form, Input, Col, Row, message } from "antd";
import React, { useEffect, useState } from "react";
import backIcon from "../../assets/images/backLeft.svg";
import { getApprovals, getapproved, getRejected } from "../../services/generic";
import { useParams } from "react-router-dom";

const WorkFlowView = ({ flowVisible,setFlowVisible }) => {
    const [approvlData,setApprovlData] = useState();
    const [formFields, setFormFields] = useState([]);
    const [currentStep,setCurrentStep] = useState()
    const [loading,setLoading] = useState(true)
    const { Step } = Steps;
    const { recordId, windowId } = useParams();
    const [form] = Form.useForm();

    useEffect(()=>{
        const fetchData = async () => {
            const approvals = await getApprovals(windowId,recordId);
            setApprovlData(JSON.parse(approvals))
            setLoading(false)
          }
          fetchData();
    },[flowVisible])

    useEffect(() => {
        if (approvlData?.length>0) {
          // Create a temporary div to manipulate HTML
          const tempDiv = document.createElement('div');
          tempDiv.innerHTML = approvlData[0].message;
    
          // Extract data from the HTML
          const fields = [];
          const strongElements = tempDiv.querySelectorAll('strong');
    
          strongElements.forEach((strongElement) => {
            const label = strongElement.textContent.trim();
            const value = strongElement.nextSibling ? strongElement.nextSibling.textContent.trim() : '';
    
            if (label) {
              fields.push({ label, value });
            }
          });
    
          // Set the form fields state
          setFormFields(fields);
          const approvalState = approvlData[0]?.approval_state ? JSON.parse(approvlData[0].approval_state) : {};

      // Ensure that approvalState is always an object
      const {
        previousState = '',
        currentState = '',
        nextState = ''
      } = approvalState || {};

      const steps = [
        previousState,
        currentState,
        nextState
      ].filter(Boolean); // Remove any null, undefined, or empty string values

      const currentStepIndex = steps.indexOf(currentState);

      setCurrentStep(currentStepIndex)
        }
      }, [approvlData]);
   
      const onApprove = async () => {
        setLoading(true)
        const values =  form.getFieldsValue()
        let approved = await getapproved(approvlData[0]?.cwf_workflow_approvals_id,values?.remarks);
        console.log(approved)
        if(approved.messageCode === '200'){
            setLoading(false)
            message.success(approved.message)
          }else{
            setLoading(false)
            message.error(approved.message)
          }
      }
    
      const onReject = async () => {
        setLoading(true)
        const values =  form.getFieldsValue()
        let rejected = await getRejected(approvlData[0]?.cwf_workflow_approvals_id,values?.remarks);
        console.log(rejected)
        if(rejected.messageCode === '200'){
            setLoading(false)
            message.success(rejected.message)
          }else{
            setLoading(false)
            message.error(rejected.message)
          }
      }

      const approvalState = approvlData?.[0]?.approval_state ? JSON.parse(approvlData[0].approval_state) : null;
//  console.log(approvalState,"----------->approvalState",approvlData)
    return (
        <>
            <Drawer visible={flowVisible} closable={false} headerStyle={{background:"#f3f4f5"}} bodyStyle={{background:"#f3f4f5"}} style={{ marginTop: "5vh" }}
                title={
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontFamily: 'Inter', padding: "2%" }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img
                                style={{
                                    display: 'inline-block',
                                    marginTop: '3px',
                                    marginRight: '8px',
                                    cursor: 'pointer',
                                }}
                                src={backIcon}
                                onClick={()=>{setFlowVisible(false)}}
                                alt="AuditTrail"
                            />
                            <h2 style={{ fontWeight: 600, fontSize: '20px', margin: 0 }}>WorkFlow</h2>
                        </div>
                        <div style={{display: approvlData?.[0]?.type === "Approval" ? 'block' : 'none' }}>
                            <Button
                               onClick={onReject}
                                key="save"
                                style={{
                                    border: '0.5px solid #A5A5A5',
                                    fontWeight: 700,
                                    cursor: 'pointer',
                                    height: '30px',
                                    fontSize: '13px',
                                }}
                            >
                                Reject
                            </Button>
                            <Button
                               onClick={onApprove}
                                key="save"
                                type="primary"
                                style={{
                                    backgroundColor: '#0C173A',
                                    color: 'white',
                                    marginLeft: '11px',
                                    fontWeight: 700,
                                    borderRadius: '4px',
                                    height: '30px',
                                    fontSize: '13px',
                                }}
                            >
                                Approve
                            </Button>
                        </div>
                    </div>
                }
            >
             <Card style={{height:"auto",padding:"2%"}}>
             <Steps labelPlacement="vertical" size='small' current={currentStep} style={{ marginTop: '5px', marginBottom: '20px', padding: "0 5%" }}>
                {approvalState?.previousState && <Step  title={approvalState.previousState} />}
                {approvalState?.currentState && <Step title={approvalState.currentState} />}
                {approvalState?.nextState && <Step title={approvalState.nextState} />}
                </Steps>
              <Form style={{fontFamily:"Inter",display: approvlData?.[0]?.type === "Approval" ? 'block' : 'none' }}form={form} initialValues={formFields} layout="vertical">
                <Row>
                {formFields?.map((item) => (
                    <Col span={24} style={{marginBottom:"2vh"}}>
                    <Form.Item  label={item.label} key={item.label} >
                    <Input style={{borderRadius:"5px"}} disabled value={item.value} />
                    </Form.Item>
                    </Col>
                ))}
                 <Col span={24} style={{marginBottom:"2vh"}}>
                    <Form.Item  label='Remarks (If Any)' key='remarks' name='remarks'>
                    <Input.TextArea style={{borderRadius:"5px"}} />
                    </Form.Item>
                    </Col>
                </Row>
                </Form>
                <div style={{ padding: '2%', fontSize: '12px', fontWeight: 400,display: approvlData?.[0]?.type === "Notification" ? 'block' : 'none'  }}>
                  <div dangerouslySetInnerHTML={{ __html: approvlData?.[0]?.message }} />
                </div>
             </Card>
            </Drawer>
        </>
    )
}

export default WorkFlowView;